import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Divider, Flex, List, Text } from "@appsmith/ads";
import { useSelector } from "react-redux";
import type { ListItemProps } from "@appsmith/ads";

import Form from "ee/components/InputsForm/Form";
import InputField from "ee/components/InputsForm/Fields/InputField";
import { getModuleInstanceInputsEvalValues } from "ee/selectors/moduleInstanceSelectors";
import { InputFieldWrapper } from "../common/InputsForm";
import type { JSCollection } from "entities/JSCollection";
import { NO_JS_INSTANCE_PARAMS, createMessage } from "ee/constants/messages";
import { kebabCase } from "lodash";

export interface ParametersViewProps {
  actions: JSCollection["actions"];
  defaultValues: Record<string, Array<string | undefined>>;
  moduleInstanceName: string;
  onUpdate: (values: Record<string, Array<string | undefined>>) => void;
  selectedActionName: string;
  setSelectedAction: (actionId: string) => void;
}

const StyledContainer = styled(Flex)`
  overflow: hidden;
  max-height: 100%;
`;

const StyledColumn = styled(Flex)`
  overflow-y: auto;
  max-height: 100%; /* Allow the item to scroll */
  & .t--no-binding-prompt {
    display: none;
  }
`;

const StyledDivider = styled(Divider)`
  height: auto;
`;

function ParametersView({
  actions,
  defaultValues,
  moduleInstanceName,
  onUpdate,
  selectedActionName,
  setSelectedAction,
}: ParametersViewProps) {
  const [triggerReset, setTriggerReset] = useState(false);
  const currentModuleInstanceRef = useRef(moduleInstanceName);
  const evaluatedDefaultValues =
    useSelector((state) =>
      getModuleInstanceInputsEvalValues(state, moduleInstanceName),
    ) || {};
  const selectedActionEvalDefaultValues =
    evaluatedDefaultValues[selectedActionName];
  const selectedAction = actions.find(
    ({ name }) => selectedActionName === name,
  );

  useEffect(() => {
    if (
      moduleInstanceName !== currentModuleInstanceRef.current &&
      !triggerReset
    ) {
      currentModuleInstanceRef.current = moduleInstanceName;
      setTriggerReset(true);
    }
  }, [moduleInstanceName, setTriggerReset, triggerReset]);

  const onResetComplete = () => {
    setTriggerReset(false);
  };

  const items: ListItemProps[] = actions.map((action) => ({
    description: "",
    title: action.name,
    onClick: () => setSelectedAction(action.id),
    isSelected: selectedActionName === action.name,
    descriptionType: "block",
  }));

  const onFormUpdate = useCallback(
    (values: { inputs: ParametersViewProps["defaultValues"] }) => {
      onUpdate(values.inputs);
    },
    [onUpdate],
  );

  const { jsArguments = [] } = selectedAction?.actionConfiguration || {};
  const hasParameters = jsArguments.length > 0;
  let anonParamCount = 1;

  return (
    <Flex
      flexDirection="column"
      gap="spaces-4"
      maxWidth={actions.length === 0 ? "100%" : "800px"}
    >
      {actions.length > 0 && (
        <>
          <Flex gap="spaces-10">
            <StyledColumn width="30%">
              <Text isBold>Function</Text>
            </StyledColumn>
            <StyledColumn width="70%">
              <Text isBold>Parameters</Text>
            </StyledColumn>
          </Flex>
          <StyledContainer gap="spaces-5">
            <StyledColumn data-testid="t--js-instance-fn-list" width="30%">
              <List items={items} />
            </StyledColumn>
            <StyledDivider orientation="vertical" />
            {/*@ts-expect-error Fix this the next time the file is edited*/}
            <StyledColumn hasParameters={hasParameters} width="70%">
              <Form
                defaultValues={{ inputs: defaultValues }}
                onResetComplete={onResetComplete}
                onUpdateForm={onFormUpdate}
                triggerReset={triggerReset}
              >
                <InputFieldWrapper data-testid="t--js-module-instance-parameter-wrapper">
                  {jsArguments.map(({ name }, index) => {
                    const title = name || `Anonymous param ${anonParamCount++}`;

                    let evaluatedValue;

                    if (Array.isArray(selectedActionEvalDefaultValues)) {
                      evaluatedValue = selectedActionEvalDefaultValues[index];
                    }

                    return (
                      <div
                        data-testid={`t--js-module-instance-parameter-field-wrapper-${kebabCase(title)}`}
                        key={`${selectedAction?.name}-${title}`}
                      >
                        <Text>{title}</Text>
                        <InputField
                          dataTreePath={`${moduleInstanceName}.inputs.${selectedActionName}[${index}]`}
                          evaluatedValue={evaluatedValue}
                          name={`inputs.${selectedActionName}.${index}`}
                        />
                      </div>
                    );
                  })}
                  {!hasParameters && (
                    <Text>{createMessage(NO_JS_INSTANCE_PARAMS)}</Text>
                  )}
                </InputFieldWrapper>
              </Form>
            </StyledColumn>
          </StyledContainer>
        </>
      )}
      {actions.length === 0 && (
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Text>No Function available</Text>
        </Flex>
      )}
    </Flex>
  );
}

export default ParametersView;
