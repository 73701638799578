import React, { memo } from "react";
import { useSelector } from "react-redux";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import { getIsModuleInstanceNameSavingStatus } from "ee/selectors/moduleInstanceSelectors";
import { saveModuleInstanceName } from "ee/actions/moduleInstanceActions";
import { useResolveIcon } from "../../Explorer/hooks";
import ActionNameEditor from "components/editorComponents/ActionNameEditor";

interface ModuleInstanceNameEditorProps {
  disabled?: boolean;
  moduleInstance: ModuleInstance;
}

function ModuleInstanceNameEditor({
  disabled,
  moduleInstance,
}: ModuleInstanceNameEditorProps) {
  const saveStatus = useSelector((state) =>
    getIsModuleInstanceNameSavingStatus(state, moduleInstance.id),
  );
  const icon = useResolveIcon({
    isLargeIcon: true,
    moduleId: moduleInstance.sourceModuleId,
  });

  return (
    <ActionNameEditor
      actionConfig={moduleInstance}
      disabled={disabled}
      enableFontStyling
      icon={icon}
      saveActionName={saveModuleInstanceName}
      saveStatus={saveStatus}
    />
  );
}

export default memo(ModuleInstanceNameEditor);
